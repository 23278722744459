import { useSessionDetailsContext } from "@/context/session-details/session-details";

import ContentLoader from "@/components/content-loader/content-loader";
import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";
import { ProgressBar } from "@/components/ui/progress-bar/progress-bar";
import Tooltip from "@/components/ui/tooltip/tooltip";

import { Title } from "../../session-detail.styles";

import { Section } from "./score-chart.styles";

export type PartialScore = {
    name: string;
    score: number;
};

export const OverallScore = () => {
    const { customChartsData, isFetchingCustomCharts } = useSessionDetailsContext();

    const overallScore = customChartsData?.filter(
        (p: any) => p.chartType === "ProgressBar" && p.code == "session-score",
    )[0];

    const overallScoreData = overallScore?.datasets[0];

    if (isFetchingCustomCharts) return <ContentLoader height="28.1875rem" />;

    return (
        <Section.Container>
            <Title>
                {overallScore?.description}
                <Tooltip simpleText={overallScore?.titleTooltip} />
            </Title>

            <Section.SubContainer>
                {overallScore.datasets.length === 0 ? (
                    <EmptyState
                        minHeight="22.1875rem"
                        title="session-detail:overall-score-empty-state"
                        emptyStateType={EmptyStateType.Charts}
                    />
                ) : (
                    <>
                        <Section.ScoreDescriptionWrapper>
                            <ProgressBar
                                width={177}
                                valueEnd={Math.round(overallScoreData?.overallScore)}
                                scoreFontSize="3rem"
                                totalScoreFontSize="1.5rem"
                            />

                            <Section.Description>
                                {overallScoreData?.overallScoreDescription}
                                <Tooltip simpleText={overallScoreData?.overallScoreToolTip} />
                            </Section.Description>
                        </Section.ScoreDescriptionWrapper>

                        <Section.ScoreDescriptionWrapper>
                            <Section.PhaseWrapper>
                                {overallScoreData?.partialScores?.map(
                                    (partialScore: PartialScore) => (
                                        <Section.ScoreWrapper key={partialScore.score}>
                                            <ProgressBar
                                                width={56}
                                                valueEnd={Math.round(partialScore.score)}
                                                scoreFontSize="1.5rem"
                                                strokeWidth={6}
                                            />
                                            <Section.Description>
                                                {partialScore.name}
                                            </Section.Description>
                                        </Section.ScoreWrapper>
                                    ),
                                )}
                            </Section.PhaseWrapper>

                            <Section.Description>
                                {overallScoreData?.partialScoreDescription}
                                <Tooltip simpleText={overallScoreData?.partialScoreTooltip} />
                            </Section.Description>
                        </Section.ScoreDescriptionWrapper>
                    </>
                )}
            </Section.SubContainer>
        </Section.Container>
    );
};
